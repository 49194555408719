import {Component, Provide, Vue} from 'vue-property-decorator';
import {GuideBoxThreeList, GuideBoxTwoList, GuideTabList} from "@/interface/pagas";

@Component({
  components: {
  },
  computed: {
  }
})
export default class Guide extends Vue {
  @Provide() tabsTime:any = null
  @Provide() tabsIndex:number = 0
  @Provide() tabsEmIndex:number = -1
  @Provide() tabs:Array<GuideTabList> = [
    {
      id:1,
      link:"",
      imgs:require("@/assets/image/guide/guide_img_0.jpg")
    },
    {
      id:2,
      link:"",
      text:"产品组合",
      class:"xian0",
      imgs:require("@/assets/image/guide/guide_img_4.jpg")
    },
    {
      id:3,
      link:"",
      text:"绣品",
      class:"xian1",
      imgs:require("@/assets/image/guide/guide_img_1.jpg")
    },
    {
      id:4,
      link:"",
      text:"主单元",
      class:"xian2",
      imgs:require("@/assets/image/guide/guide_img_2.jpg")
    },
    {
      id:5,
      link:"",
      text:"辅单元",
      class: "xian3",
      imgs:require("@/assets/image/guide/guide_img_3.jpg")
    },
  ]

  @Provide() topShowIndex:number = -1
  @Provide() box2:Array<GuideBoxTwoList> = [
    {
      id:1,
      link:"",
      imgs:require("@/assets/image/guide/guide_img_10.jpg"),
      icon:require("@/assets/image/guide/guide_icon_0.png"),
      title:"绣 品",
      title2:"(必选项)",
      text:'刺绣作品：本商城绣品均为全手工刺绣作品，绣品图案可在推荐图库中选择，也可选择“自定义图片”上传定制。',
      topMinNum:600,
      topMaxNum:899,
    },
    {
      id:2,
      link:"",
      imgs:require("@/assets/image/guide/guide_img_11.jpg"),
      icon:require("@/assets/image/guide/guide_icon_1.png"),
      title:"主单元",
      title2:"(必选项)",
      text:'主单元：可以嵌入刺绣作品的产品单元，也是产品的主体，与辅单元组合搭配，也可多个同款主单元套装搭配。',
      topMinNum:900,
      topMaxNum:1199,
    },
    {
      id:3,
      link:"",
      imgs:require("@/assets/image/guide/guide_img_12.jpg"),
      icon:require("@/assets/image/guide/guide_icon_2.png"),
      title:"辅单元",
      title2:"(非必选)",
      text:'辅单元：不能嵌入刺绣作品的产品单元，与主单元组合搭配，优化产品整体视觉效果，并根据需要调整产品整体宽度。',
      topMinNum:1200,
      topMaxNum:1500,
    }
  ]
  @Provide() box3:Array<GuideBoxThreeList> = [
    {
      id:1,
      link:"",
      title:"主辅单元拼合搭配",
      imgSrc:require("@/assets/image/guide/guide_img_20.jpg"),
      imgURL:require("@/assets/image/guide/guide_img_20.jpg"),
      imgURLToo:require("@/assets/image/guide/guide_img_23.jpg")
    },
    {
      id:2,
      link:"",
      title:"主辅单元混组搭配",
      imgSrc:require("@/assets/image/guide/guide_img_21.jpg"),
      imgURL:require("@/assets/image/guide/guide_img_21.jpg"),
      imgURLToo:require("@/assets/image/guide/guide_img_24.jpg")
    },
    {
      id:3,
      link:"",
      title:"多主单元套装搭配",
      imgSrc:require("@/assets/image/guide/guide_img_22.jpg"),
      imgURL:require("@/assets/image/guide/guide_img_22.jpg"),
      imgURLToo:require("@/assets/image/guide/guide_img_25.jpg")
    },
  ]
  @Provide() mouseiconShow:boolean = false



  created(){
    const _this:any = this;
  }


  mounted(){
    const _this:any = this;
    window.addEventListener('scroll',_this.handleScroll)
  }


  /**
   * @Author HS
   * @Date 2021/7/8 3:24 下午
   * @Description: 监听滚动
   * @Params: null
   * @Return: null
   */
  handleScroll(){
    const _this:any = this;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
    _this.box2.map((item:GuideBoxTwoList,index:number) => {
      if(scrollTop >= item.topMinNum && scrollTop < item.topMaxNum){
        _this.topShowIndex = index
      }
    })
    if(scrollTop < _this.box2[0].topMinNum){
      _this.topShowIndex = -1
    }
    else if(scrollTop > _this.box2[_this.box2.length-1].topMaxNum){
      _this.topShowIndex = -1
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/9 2:46 下午
   * @Description: tab切换
   * @Params: { number ： index - true } [下标]
   * @Return: null
  */
  tabsChageFn(index:number){
    console.log(index);
    const _this:any = this;
    if(index==1)return;
    _this.tabsIndex == index ? _this.tabsIndex = 0 :  _this.tabsIndex = index
  }

  /**
   * @Author HS
   * @Date 2021/9/15 5:19 下午
   * @Description: tab移出
   * @Params: null
   * @Return: null
   */
  tabsMouseoutFn(){
    const _this:any = this;
    _this.tabsTime = setTimeout(()=>{
      _this.tabsIndex = 0;
      _this.tabsEmIndex = -1
      clearTimeout(_this.tabsTime)
    },1000)
  }
  /**
   * @Author HS
   * @Date 2021/9/15 5:19 下午
   * @Description: tab移入
   * @Params: { number ： index - true } [下标]
   * @Return: null
   */
  tabsMouseoverFn(index:number){
    const _this:any = this;
    if(!_this.$base.isNull(_this.tabsTime)){
      clearTimeout(_this.tabsTime)
    }
    _this.tabsEmIndex = index

  }


  /**
   * @Author HS
   * @Date 2021/7/9 2:47 下午
   * @Description: 图片切换
   * @Params: { object ： item - true } [当前]
   * @Return: null
  */
  imgClickChage(item:GuideBoxThreeList){
    const _this:any = this;
    if(item.imgSrc == item.imgURL){
      item.imgSrc = item.imgURLToo
    }
    else{
      item.imgSrc = item.imgURL
    }
  }
  /**
   * @Author HS
   * @Date 2021/7/9 2:47 下午
   * @Description: 图片切换
   * @Params: { object ： item - true } [当前]
   * @Return: null
   */
  imgMouseoverChage(item:GuideBoxThreeList){
    const _this:any = this;
    item.imgSrc = item.imgURL
    _this.mouseiconShow = true
    const div:any = document.querySelector('.mouseicon')
    document.onmousemove = function (event:MouseEvent){
      event = event || window.event
      let st = document.body.scrollTop || document.documentElement.scrollTop;
      let sl = document.body.scrollLeft || document.documentElement.scrollLeft;
      let x = event.clientX
      let y = event.clientY
      div.style.left = x + sl + 20 + 'px'
      div.style.top = y + st + 10 + 'px'
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/9 2:47 下午
   * @Description: 图片切换
   * @Params: { object ： item - true } [当前]
   * @Return: null
   */
  imgMouseoutChage(item:GuideBoxThreeList){
    const _this:any = this;
    console.log(11111111111)
    item.imgSrc = item.imgURL
    _this.mouseiconShow = false
    document.onmousemove = null;
  }


  /**
   * @Author HS
   * @Date 2021/7/9 2:48 下午
   * @Description: 去定制
   * @Params: { 类型 ： 字段 - 是否必须 } [参数：]
   * @Return: null
  */
  goDiy() {
    const _this:any = this;
    if(_this.$base.isLogin()){
      const path = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path,"diy")
      _this.$base.goRouter(path)
      return
    }
    _this.$base.goRouter(_this.$IndexConfig.diy.path)
  }

}
